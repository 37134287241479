import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Grid, Sheet, Tab, TabList, Tabs, Card, Typography, AspectRatio, Chip, Button, Box, Divider } from "@mui/joy";
import FilterDramaRoundedIcon from '@mui/icons-material/FilterDramaRounded';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import "./App.css"

export default function App() {
  return (
    <div>
      <Layout/>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
          {/* <Route index element={<Home />} /> */}
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
        {/* </Route> */}
      </Routes>
    </div>
  );
}

function Layout() {
  const vals = window.location.href.split('/')
  const [index, setIndex] = useState('/'+vals[vals.length-1]);
  return (
    <div>
      <nav>
      <Grid container>
        <Grid sm={12} md={6} sx={{p:5, pb:3, display:'flex', md:{pb:0}}}>
          <Typography fontSize={48} sx={{ display:'flex', alignItems:'end', md:{pb:0}}} color="primary">
            Uni Connect Tech
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Sheet sx={{backgroundColor:'inherit'}}>
            <Tabs
              variant="outlined"
              color="primary"
              aria-label="Plain tabs"
              value={index}
              onChange={(event, value) => {
                console.log(value)
                window.location.href=value
                setIndex(value)
              }}
              sx={{ borderRadius: 'md',m:5, mb:3, p:1, boxShadow:'lg' }}
            >
              <TabList variant="plain" size="lg" sx={{ boxShadow:'none'}}>
                <Tab variant={index === "/home" ? 'solid' : 'plain'} color="primary" value="/home">Home</Tab>
                <Tab variant={index === "/about" ? 'solid' : 'plain'} color="primary" value="/about">About</Tab>
                <Tab variant={index === "/contact" ? 'solid' : 'plain'} color="primary" value="/contact">Contact</Tab>
              </TabList>
            </Tabs>
          </Sheet>
        </Grid>
      </Grid>
      </nav>
    </div>
  );
}

function Home() {
  return (
    <>
    <Card
      variant="outlined"
      color="primary"
      row
      sx={{ boxShadow:'lg', m:5, mt:0 }}
    >
    <Grid container> 
      <Grid xs={12} sm={12} md={7}>
      <AspectRatio ratio="2" sx={{pb:2}}>
        <img
          src="experts.webp"
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      </Grid>
      <Grid xs={12} sm={12} md={5}>
      <div>
        <Typography level="h2" fontSize="xl" mb={2}>
          Our Mission
        </Typography>
        <Typography fontSize="md" aria-describedby="card-description" mb={1}>
        Our mission is to empower businesses and organizations to leverage the full potential of technology to achieve their goals. We strive to be the trusted partner of choice for our clients, delivering exceptional service and expertise that helps them succeed in an increasingly digital world. We believe that technology has the power to transform the way businesses operate and drive innovation and growth, and we are dedicated to helping our clients navigate the complex and rapidly-evolving world of technology. Through our commitment to excellence and our focus on customer satisfaction, we aim to be the premier provider of tech services to businesses and organizations of all sizes.
        </Typography>
        <Chip
          variant="outlined"
          color="primary"
          size="lg"
          onClick={()=>{window.location.href="/contact"}}
        >
          Contact us
        </Chip>
      </div>
      </Grid>
    </Grid>
    </Card>
    <Card
      variant="outlined"
      color="primary"
      row
      sx={{ boxShadow:'lg', m:5, mt:0 }}
    >
    <Grid container> 
      <Grid xs={12} sm={12} md={7}>
      <AspectRatio ratio="2" sx={{pb:2 }}>
        <img
          src="team.webp"
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      </Grid>
      <Grid xs={12} sm={12} md={5}>
      <div>
        <Typography level="h2" fontSize="xl" mb={2}>
          Our Services
        </Typography>
        <Typography fontSize="md" aria-describedby="card-description" mb={1}>
        As a tech services company, we are dedicated to helping businesses and organizations leverage the power of technology to improve efficiency, drive innovation, and achieve their goals. Our team of experienced professionals has a diverse range of technical skills and expertise, including expertise in cloud computing, data analytics, software development, and cybersecurity. We offer a range of services to help our clients optimize their IT systems and infrastructure, including IT strategy consulting, technology implementation, and ongoing support and maintenance. We work closely with our clients to understand their unique needs and challenges, and develop tailored solutions that meet their specific requirements. Whether you're looking to modernize your IT infrastructure, improve your cybersecurity posture, or drive digital transformation, we're here to help you succeed.
        </Typography>
        <Chip
          variant="outlined"
          color="primary"
          size="lg"
          onClick={()=>{window.location.href="/contact"}}
        >
          Contact us
        </Chip>
      </div>
      </Grid>
    </Grid>
    </Card>

    <Typography level="h1" fontSize="xxl" sx={{m:5, mt:0, textAlign:'center'}}>What we do</Typography>
    <Grid container spacing={3} sx={{mx:5}}>
      <Grid sm={6} md={3}>
        <Card variant="outlined" color="primary" sx={{boxShadow:'lg'}}>
          <AspectRatio>
            <FilterDramaRoundedIcon color='primary' sx={{backgroundColor:'white'}}/>
          </AspectRatio>
          <Typography level="h1" fontSize='lg' sx={{p:1}}> Cloud Services</Typography>
          <Typography fontSize='md' sx={{p:1, pt:0}}>
          We offer a range of services to help businesses and organizations migrate to the cloud, optimize their cloud infrastructure, and take full advantage of the benefits of cloud computing.
          </Typography>
        </Card>
      </Grid>
      <Grid sm={6} md={3}>
        <Card  variant="outlined" color="primary" sx={{boxShadow:'lg'}}>
        <AspectRatio>
            <DataObjectOutlinedIcon color='primary' sx={{backgroundColor:'white'}}/>
          </AspectRatio>
          <Typography level="h1" fontSize='lg' sx={{p:1}}>Data Solutions</Typography>
          <Typography fontSize='md' sx={{p:1, pt:0}}>
          We specialize in helping businesses and organizations transform their data into actionable insights that drive better decision-making, improve efficiency, and drive growth.
          </Typography>
        </Card>
      </Grid>
      <Grid sm={6} md={3}>
        <Card  variant="outlined" color="primary" sx={{boxShadow:'lg'}}>
        <AspectRatio>
            <CodeOutlinedIcon color='primary' sx={{backgroundColor:'white'}}/>
          </AspectRatio>
          <Typography level="h1" fontSize='lg' sx={{p:1}}>Implementation</Typography>
          <Typography fontSize='md' sx={{p:1, pt:0}}>
          We implement and integrate new technology systems and solutions, ensuring that they are fully optimized and aligned with our clients business goals.</Typography>
        </Card>
      </Grid>
      <Grid sm={6} md={3}>
        <Card  variant="outlined" color="primary" sx={{boxShadow:'lg'}}>
        <AspectRatio>
            <EmojiPeopleOutlinedIcon color='primary' sx={{backgroundColor:'white'}}/>
          </AspectRatio>
          <Typography level="h1" fontSize='lg' sx={{p:1}}>Staffing</Typography>
          <Typography fontSize='md' sx={{p:1, pt:0}}>
          We specialize in providing temporary and permanent staffing solutions to businesses and organizations. Our goal is to help our clients find the right talent to drive success.</Typography>
        </Card>
      </Grid>
    </Grid>
    <div style={{textAlign:'center'}}>
      <Button
            variant="outlined"
            color="primary"
            size="lg"
            onClick={()=>{window.location.href="/contact"}}
            sx={{borderRadius:'40px', my:4, borderWidth:'3px', p:3}}
          >
            Get in touch with us!
      </Button>
    </div>
    </>
  );
}

function About() {
  return (
    <Card variant="outlined" color="primary" sx={{m:5, mt:2, boxShadow:'lg'}}>
      <Grid container>
        <Grid xs={12} md={6}>
          <AspectRatio ratio='1'>
          <img
            src="resume.webp"
            loading="lazy"
            alt=""
          />
          </AspectRatio>
        </Grid>
        <Grid xs={12} md={6}>  
          <Typography fontSize={28}>About Us <Chip
            size="lg"
            variant="soft"
          > We are e-verified! </Chip></Typography>
          <Divider sx={{my:2}}/>
          <Typography fontSize={20}>
          Our main focus is to provide expert guidance and support to businesses looking to improve their technology systems and processes. With a team of highly skilled and experienced professionals, we are able to assess the needs of our clients and develop customized solutions to meet their unique challenges.
          <Divider sx={{my:2}}/>
          Our services range from IT strategy consulting to implementation and support. We work with a wide range of industries, from small startups to large enterprises, and are able to adapt to the specific needs of each client.
          <Divider sx={{my:2}}/>
          In addition to traditional IT consulting services, we also offer cloud consulting and cybersecurity services to help businesses stay protected and secure in today's increasingly digital world. We understand the importance of staying up to date with the latest technologies and are committed to helping our clients stay ahead of the curve.
          </Typography>
          <Chip
            sx={{mt:3, p:3, borderRadius:'40px'}}
            variant="outlined"
            color="primary"
            size="lg"
            onClick={()=>{window.location.href="/contact"}}
          >
            Ask us more
          </Chip>
        </Grid>
      </Grid>
    </Card>
  );
}

function Contact() {
  return (
    <Card variant="outlined" color="primary" sx={{m:5, mt:2, boxShadow:'lg'}}>
      <Grid container>
        <Grid xs={12} md={8}>
          <AspectRatio>
          <img
            src="email.webp"
            loading="lazy"
            alt=""
          />
          </AspectRatio>
        </Grid>
        <Grid xs={12} md={4}>  
          <Typography fontSize={28}>Let's get in touch!</Typography>
          <Divider sx={{my:2}}/>
          <Typography fontSize={20}>Drop us an email 
            <Chip variant="soft" startDecorator={<AlternateEmailIcon />} sx={{ml:2}}>
              <a href="mailto:hr@uniconnecttech.com">hr@uniconnecttech.com</a>
            </Chip>
          </Typography>
          
        </Grid>
      </Grid>
    </Card>
  );
}